import Reset from "@sellernote/shared/src/sds-v2/components/svgIcons/ArrowRotateOneArrowIcon";
import ChevronDown from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronDownIcon";
import ChevronLeft from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronLeftIcon";
import ChevronRight from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronRightIcon";
import ChevronUp from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronUpIcon";
import InfoOutlinedIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/InfoOutlinedIcon";
import ToggleOff from "@sellernote/shared/src/sds-v2/components/svgIcons/ToggleStateOffIcon";
import ToggleOn from "@sellernote/shared/src/sds-v2/components/svgIcons/ToggleStateOnIcon";
import Tooltip from "@sellernote/shared/src/sds-v2/components/Tooltip";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import { ViewType } from ".";
import { MSWToolMode } from "./useChangeAllHandlers";
import { Handler } from "./useHandlerData";
import Styled from "./index.styles";

export default function ToggleButtons({
  isOpenedTool,
  activatedHandlerList,
  type,
  handleMSWToolToggle,
  mode,
  handleModeToggle,
  deactivateAllHandlers,
}: {
  isOpenedTool: boolean;
  activatedHandlerList: Handler[];
  type: ViewType;
  handleMSWToolToggle: () => void;
  mode: MSWToolMode;
  handleModeToggle: () => void;
  deactivateAllHandlers: () => void;
}) {
  return (
    <Styled.ToggleButton
      mode={mode}
      isToolOpened={isOpenedTool}
      isActive={activatedHandlerList.length > 0}
    >
      <Tooltip
        title={{
          Icon: InfoOutlinedIcon,
          message: `현재 동작중인 핸들러 : ${activatedHandlerList.length}개`,
        }}
        desc={
          <>
            {mode === "error" && (
              <b>** 모든 요청에 500 에러를 반환하고 있습니다! **</b>
            )}
            <ul className="handler-list">
              {activatedHandlerList.map((h) => (
                <li key={h.handler}>
                  [{h.type}] {h.handler}
                </li>
              ))}
            </ul>
          </>
        }
        position="bottomLeft"
      >
        <span className="toggle" onClick={handleMSWToolToggle}>
          {type === "PDA" ? (
            isOpenedTool ? (
              <ChevronUp />
            ) : (
              <ChevronDown />
            )
          ) : isOpenedTool ? (
            <ChevronLeft />
          ) : (
            <ChevronRight />
          )}
        </span>
      </Tooltip>

      {isOpenedTool && (
        <div className="change-all-handlers">
          <span className="mode" onClick={handleModeToggle}>
            {mode === "normal" ? (
              <ToggleOff color={COLOR.grayScale_700} />
            ) : (
              <ToggleOn color={COLOR.error_200} />
            )}
          </span>

          <Reset
            width="1.5rem"
            height="1.5rem"
            type="reset"
            color={COLOR.primary_500}
            onClick={deactivateAllHandlers}
          />
        </div>
      )}
    </Styled.ToggleButton>
  );
}
