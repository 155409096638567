import { useAppQueryWithQueryKeyFactory } from "@sellernote/shared/src/services/query";

import { ExchangeRate } from "../../../types/forwarding/trello";

import { GET_DYNAMIC_EXCHANGE_RATE_LIST_REQ } from "../../../api-interfaces/shipda-api/admin/adminTradingStatement";

export const ADMIN_TRADING_STATEMENT_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_TRADING_STATEMENT_QUERY" }] as const,

  getDynamicExchangeList: (params: GET_DYNAMIC_EXCHANGE_RATE_LIST_REQ) =>
    [
      {
        ...ADMIN_TRADING_STATEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getTradingStatementExchangeList",
      },
    ] as const,
};

/**
 * 거래명세서에서 날짜에 딸라 변동이 되는 환율 데이터
 * - COMMON에 같은 path가 있지만 사용방식이 달라 따로 추가
 */
function useGetDynamicExchangeList({
  params,
  onSuccess,
}: {
  params: GET_DYNAMIC_EXCHANGE_RATE_LIST_REQ;
  onSuccess?: (data: ExchangeRate[]) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_TRADING_STATEMENT_QUERY_KEY_GEN.getDynamicExchangeList
    >,
    ExchangeRate[]
  >({
    queryKey:
      ADMIN_TRADING_STATEMENT_QUERY_KEY_GEN.getDynamicExchangeList(params),
    requestOptions: {
      method: "get",
      path: "/exchange",
      apiType: "SHIPDA_ADMIN_API",
      params,
    },
    onSuccess,
  });

  return { ...queryResult };
}

const ADMIN_TRADING_STATEMENT_QUERY = {
  useGetDynamicExchangeList,
};

export default ADMIN_TRADING_STATEMENT_QUERY;
