import { rest } from "msw";

import { getShipdaDevNewAPI } from "@sellernote/shared/src/services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import {
  AIR_QUOTATION_LIST,
  FCL_QUOTATION_LIST_FOR_SORTING_TEST,
  GET_PARTNER_DETAIL_DUMMY,
  LCL_QUOTATION_LIST,
  MYPAGE_BID_LIST,
} from "./data";

export const downloadAllAttachments = rest.get(
  getShipdaDevNewAPI("/attachments/send/email/103382"),
  (req, res, ctx) => res(ctx.status(200), ctx.delay(1000), ctx.json({}))
);

export const getUserBidList = rest.get(
  getShipdaDevNewAPI("/bid/v2/user"),
  (req, res, ctx) =>
    res(ctx.status(400), ctx.delay(1000), ctx.json({ code: 400 }))
);

// 회사소개 가이드북 요청, 보풀 사전신청
export const requestGuide = rest.post(
  getShipdaDevNewAPI("/customerCare/guide"),
  (req, res, ctx) => {
    const isNotName400Error = { code: 400, error: "is not name" }; // 이름을 정확하게 입력해주세요 문구 노출
    const default400Error = { code: 400, error: "" }; // 잘못된 요청입니다 문구 노출
    const defaultError = { code: 404, error: "" }; // 고객센터에 문의해주세요 문구 노출

    return res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(isNotName400Error)
    );
  }
);

export const updateExporterInfo = rest.put(
  getShipdaDevNewAPI("/bid/user/exporterInfo"),
  (req, res, ctx) => {
    const isAlreadyUpdateError = { code: 400, error: "already updated" }; // 변경된 내역이 없습니다 문구 노출
    const defaultError = { code: 404, error: "" }; // 고객센터에 문의해주세요 문구 노출

    return res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(isAlreadyUpdateError)
    );
  }
);

export const getFCLQuotationForSortingTest = rest.get(
  getShipdaDevNewAPI("/searchEstimate/fcl/schedule"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(FCL_QUOTATION_LIST_FOR_SORTING_TEST)
    );
  }
);

export const getLCLQuotation = rest.get(
  getShipdaDevNewAPI("/searchEstimate/lcl/schedule"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(LCL_QUOTATION_LIST)
    );
  }
);

export const getPartnerDetail = rest.get(
  getShipdaDevNewAPI("/contractor/1"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(GET_PARTNER_DETAIL_DUMMY)
    );
  }
);

export const getAirQuotation = rest.get(
  getShipdaDevNewAPI("/searchEstimate/air/schedule"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(AIR_QUOTATION_LIST)
    );
  }
);

// TODO: 상황에 맞게 쿼리파람 설정을 하거나 전용 호출을 추가
export const getMypageBidList = rest.get(
  getShipdaDevNewAPI("/bid/v2/list"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(MYPAGE_BID_LIST)
    );
  }
);
